
































































































































































































import Vue, { VueConstructor } from 'vue';
import OwnerPlayroomService from '@/services/owner/OwnerPlayroomService';
import PublicPlayroomService from '@/services/public/PublicPlayroomService';
import { required, minLength } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';
import ValidatedInput from '@/components/forms/ValidatedInput.vue';
import ValidatedSelect from '@/components/forms/ValidatedSelect.vue';
import ToastMessage from '@/models/ToastMessage';
import DataTable from '@/components/shared/DataTable.vue';
import CreateCard from '@/components/forms/CreateCard.vue';
import Jumbotron from '@/components/shared/Jumbotron.vue';
import Rating from '@/components/playrooms/Rating.vue';
import { format } from 'date-fns';
import { de } from 'date-fns/locale';
import { toDate } from 'date-fns-tz';
import FormaterMixin from '@/mixins/FormaterMixin.vue';
import { MetaInfo } from 'vue-meta';

const validations = {
  newPlayroom: {
    name: {
      required,
      minLength: minLength(5),
    },
    playroomIdToCopy: {},
  },
};

export default (Vue as VueConstructor<Vue & InstanceType<typeof FormaterMixin>>).extend({
  mixins: [validationMixin, FormaterMixin],
  validations,
  components: {
    ValidatedInput,
    DataTable,
    Jumbotron,
    CreateCard,
    ValidatedSelect,
    Rating,
  },
  data() {
    return {
      tableFilterValue: '',
      data: { playrooms: { playrooms: [], pages: 0, totalItemCount: 0 }, allPlayrooms: [] },
      sorterValue: { column: 'id', asc: true },
      currentPage: 1,
      loading: false,
      newPlayroom: { name: '', playroomIdToCopy: '' },
      message: '',
      showDeleteModal: false,
      playroomIdToDelete: '',
      ready: false,
    };
  },
  metaInfo(): MetaInfo {
    return {
      title: this.$t('pages.host.HostPlayroomIndex.meta.title').toString(),
    };
  },
  watch: {
    sorterValue: function () {
      this.loadData();
    },
    currentPage: function () {
      this.loadData();
    },
    tableFilterValue: function () {
      this.loadData();
    },
  },
  computed: {
    playroomCopyOptions(): { value: string; label: string }[] {
      const playroomData = this.data.allPlayrooms.map((playroom: { id: string; name: string }) => {
        return { value: playroom.id, label: playroom.name };
      });
      return [
        { value: '', label: this.$t('pages.owner.playrooms.OwnerPlayroomIndex.copyNothing').toString() },
        ...playroomData,
      ];
    },
    showTable(): boolean {
      if (!this.ready) {
        return true;
      }
      return this.data.playrooms.totalItemCount > 0;
    },
  },
  mounted() {
    this.loadData();
    this.setBreadCrumb();
  },
  methods: {
    setBreadCrumb() {
      this.$store.commit('UiStoreModule/setBreadcrumbItems', [
        { translationKey: 'pages.host.HostPlayroomIndex.breadcrumb.last' },
      ]);
    },

    formatDate(isoDate: string, format: 'P') {
      return this.formatIsoDateTime(isoDate, format);
    },

    loadData() {
      this.loading = true;
      OwnerPlayroomService.getIndex(
        this.currentPage,
        this.sorterValue.column,
        this.sorterValue.asc,
        this.tableFilterValue
      ).then((res) => {
        this.loading = false;
        this.ready = true;
        this.data = res.value;
      });
    },

    dateDisplay(dateString: string): string {
      var date = toDate(dateString);
      return format(date, 'Pp', { locale: de });
    },

    deletePlayroom(playroomId: string) {
      this.showDeleteModal = true;
      this.playroomIdToDelete = playroomId;
    },

    confirmDelete() {
      this.showDeleteModal = false;
      this.loading = true;
      return OwnerPlayroomService.delete(this.playroomIdToDelete).then(
        () => {
          this.loadData();
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.delete_successful').toString(), 'bg-success')
          );
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.delete_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },

    downloadQrCodeUrl(playroomId: string) {
      return PublicPlayroomService.getQrCodeUrl(playroomId);
    },

    handleSubmit() {
      this.loading = true;
      return OwnerPlayroomService.createNew(this.newPlayroom).then(
        (resp) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_successful').toString(), 'bg-success')
          );
          this.$store.dispatch('NextActivitiesStoreModule/playroomCreated');
          this.$router.push({
            name: 'OwnerPlayroomEditBasicInformation',
            params: { playroomId: resp.value, newPlayroom: 'true' },
          });
        },
        (error) => {
          this.$store.commit(
            'addToastMessage',
            new ToastMessage(this.$t('forms.common.save_error').toString(), 'bg-warning')
          );
          this.loading = false;
          this.message = error;
        }
      );
    },
  },
});
